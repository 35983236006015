'use client';

import React from 'react';
import { Text } from '@depop/web-ui-kit/Typography/Text';
import { useTranslations } from 'next-intl';
import { useParams } from 'next/navigation';

import { ShopEmptyState } from '../../../components/EmptyState';

import { useRepopOnEmptyShop } from '@/modules/shop/hooks/useRepopOnEmptyShop';
import { RepopEmptyState } from '@/app/[locale]/[username]/(shop)/components/RepopEmptyState';

export function SoldProductsEmptyState() {
  const t = useTranslations('shop');
  const { username } = useParams<{ username: string; locale: string }>();
  const { products, showProducts } = useRepopOnEmptyShop();

  if (showProducts) {
    return <RepopEmptyState products={products} />;
  }

  return (
    <ShopEmptyState>
      <Text>{t('NoSold', { username })}</Text>
    </ShopEmptyState>
  );
}
