'use client';

import React from 'react';
import { LoadingBall } from '@depop/web-ui-kit/LoadingBall';
import { Modal } from '@depop/web-ui-kit/Modal';
import { Text } from '@depop/web-ui-kit/Typography/Text';
import { H3 } from '@depop/web-ui-kit/Typography/H3';
import { useTranslations } from 'next-intl';

import styles from './styles.module.css';

import { InfiniteScroll } from '@/components/InfiniteScroll';
import { Price } from '@/components/Price';
import { ProductCard } from '@/components/ProductCard';
import { useLikedProducts } from '@/modules/shop/hooks/useLikedProducts';
import { useShopByUsername } from '@/modules/shop/hooks/useShopByUsername';
import { useTracking } from '@/modules/depopEdit/useTracking';
import { transformShopProduct } from '@/modules/shop/helpers/transformShopProduct';
import { getProductsFromPaginatedResponse } from '@/modules/product/helpers/getProductsFromPaginatedResponse';
import { BOOSTED_TEXT_ANY_LOCALE } from '@/constants/boostedListings';
import { LikeButton } from '@/app/[locale]/(browse)/components/BrowseProductCard/LikeButton';
import { isSold } from '@/modules/product/helpers/isSold';
import { getSizeString } from '@/modules/product/helpers/getSizeString';

type ShopLikedProductsModuleModalProps = {
  isOpen: boolean;
  setIsOpen: (arg: boolean) => void;
};

function Content() {
  const { data: sellerData } = useShopByUsername();
  const { data, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useLikedProducts({
      sellerId: sellerData?.data.id,
      hideSoldProducts: false,
    });
  const t = useTranslations('common');
  const { sendModuleClickAction } = useTracking();

  const products = getProductsFromPaginatedResponse(data, transformShopProduct);

  return (
    <div className={styles.modalContainer}>
      <InfiniteScroll
        onEndReached={fetchNextPage}
        hasMore={hasNextPage}
        LoadingComponent={<LoadingBall />}
        loading={isFetchingNextPage}
      >
        <ul className={styles.productListModalContainer}>
          {products &&
            products.map(
              (
                {
                  id,
                  slug,
                  preview,
                  price,
                  sizes,
                  brandName,
                  isBoosted,
                  isLiked,
                  status,
                },
                index
              ) => {
                return (
                  <li key={id}>
                    <ProductCard
                      productHref={slug}
                      preview={preview}
                      handleClick={() => sendModuleClickAction(slug, id, index)}
                      ProductAttributes={() => (
                        <div className={styles.productAttributesModalContainer}>
                          <div>
                            <Price
                              currencyCode={price.currencyName}
                              price={price.priceAmount}
                              discountedPrice={price.discountedPriceAmount}
                              inPreview
                            />
                            {sizes && (
                              <Text className={styles.sizeAttributeText}>
                                {getSizeString({
                                  sizes,
                                  multipleSizesString: 'Multiple sizes',
                                })}
                              </Text>
                            )}
                            {brandName && <Text>{brandName}</Text>}
                          </div>
                          {isBoosted && (
                            <Text type="caption2" className={styles.boostedTag}>
                              {BOOSTED_TEXT_ANY_LOCALE}
                            </Text>
                          )}
                        </div>
                      )}
                      Overlay={() => (
                        <div className={styles.gradientOverlay}>
                          <LikeButton
                            productId={id}
                            initialLikedState={!!isLiked}
                            isProductBoosted={isBoosted}
                            iconSize={24}
                          />

                          {isSold(status) && (
                            <H3 className={styles.soldText} as="p">
                              {t('SoldSingular')}
                            </H3>
                          )}
                        </div>
                      )}
                    />
                  </li>
                );
              }
            )}
        </ul>
      </InfiniteScroll>
    </div>
  );
}

export function ShopLikedProductsModuleModal({
  isOpen,
  setIsOpen,
}: ShopLikedProductsModuleModalProps) {
  const { data: sellerData } = useShopByUsername();

  const tShop = useTranslations('shop');
  const shopName = sellerData?.data.first_name;

  const capitalizedShopName =
    shopName && shopName?.charAt(0).toUpperCase() + shopName?.slice(1);
  return (
    <Modal
      isOpen={isOpen}
      setOpen={setIsOpen}
      content={() => <Content />}
      title={tShop('SellersLikes', { capitalizedShopName })}
      wide
    />
  );
}
