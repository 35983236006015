import { useEffect, useState } from 'react';
import { useResponsive } from '@depop/web-ui-kit/useResponsive';

import { useExperiments } from '@/modules/experiments/useExperiments';
import { VARIANT_IDENTIFIER } from '@/modules/experiments/config';
import { useIsViewingOwnShop } from '@/modules/shop/hooks/useIsViewingOwnShop';
import { useFilteredShopProducts } from '@/modules/shop/hooks/useFilteredShopProducts';
import { useSearchFilters } from '@/modules/search/useSearchFilters';
import { getActiveFiltersCount } from '@/modules/search/helpers';

export function useShopFilters(sellerId?: number) {
  const { isMaxSmall: isMobile } = useResponsive();
  const { searchFilters } = useSearchFilters();
  const productsQuery = useFilteredShopProducts(sellerId);
  const isViewingOwnShop = useIsViewingOwnShop();
  const [{ web_6232_shop_mobile_filters }, bucketFn] = useExperiments(
    ['web_6232_shop_mobile_filters'],
    { deferred: true }
  );
  const [hasProductsOnPageLoad, setHasProductsOnPageLoad] = useState(false);

  const hasActiveFilters = getActiveFiltersCount(searchFilters) > 0;
  const initialPageLength =
    productsQuery.data?.pages?.[0].data?.products?.length || 0;

  useEffect(() => {
    if (initialPageLength > 0 && !hasProductsOnPageLoad) {
      setHasProductsOnPageLoad(true);
    }
  }, [initialPageLength, hasProductsOnPageLoad]);

  useEffect(() => {
    if (isMobile && !isViewingOwnShop && hasProductsOnPageLoad) {
      bucketFn();
    }
  }, [isMobile, isViewingOwnShop, hasProductsOnPageLoad]);

  const showFilters =
    !isViewingOwnShop && (hasProductsOnPageLoad || hasActiveFilters);

  return {
    showMobile:
      showFilters && web_6232_shop_mobile_filters === VARIANT_IDENTIFIER,
  };
}
