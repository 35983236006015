import { useInfiniteQuery } from '@tanstack/react-query';

import { RQ_SHOP_PRODUCTS } from '@/modules/ReactQuery/cacheKeys';
import { fetchProducts } from '@/modules/shop/api';
import { SHOP_PAGE_SIZE } from '@/modules/shop/constants';
import { useExperiments } from '@/modules/experiments/useExperiments';
import { VARIANT_IDENTIFIER } from '@/modules/experiments/config';

type UseShopProductsParams = {
  sellerId?: number;
  brandIds?: string;
  targetProductId?: number;
};

export function useShopProducts({
  sellerId,
  brandIds,
  targetProductId,
}: UseShopProductsParams) {
  const { web_upfront_fees } = useExperiments(['web_upfront_fees']);

  return useInfiniteQuery({
    queryKey: [RQ_SHOP_PRODUCTS, sellerId],
    queryFn: ({ pageParam }) => {
      return fetchProducts({
        userId: sellerId as number,
        targetProductId,
        req: {
          params: {
            after: pageParam || undefined,
            limit: SHOP_PAGE_SIZE,
            brand_ids: pageParam ? undefined : brandIds,
            force_fee_calculation: web_upfront_fees === VARIANT_IDENTIFIER,
          },
        },
      });
    },
    initialPageParam: '',
    getNextPageParam: (lastPage) => {
      const { end, last_offset_id } = lastPage.meta;
      return !end && last_offset_id ? last_offset_id : null;
    },
    enabled: !!sellerId,
  });
}
