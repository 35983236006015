'use client';

import React, { useState } from 'react';
import { Text } from '@depop/web-ui-kit/Typography/Text';
import { useTranslations } from 'next-intl';
import { useResponsive } from '@depop/web-ui-kit/useResponsive';
import { ButtonLink } from '@depop/web-ui-kit/ButtonLink';

import { ProductListCard } from '../ProductList/ProductListCard';

import styles from './styles.module.css';
import { ShopLikedProductsModuleModal } from './ShopLikedProductsModuleModal';

import { useLikedProducts } from '@/modules/shop/hooks/useLikedProducts';
import { useShopByUsername } from '@/modules/shop/hooks/useShopByUsername';
import { getProductsFromPaginatedResponse } from '@/modules/product/helpers/getProductsFromPaginatedResponse';
import { transformShopProduct } from '@/modules/shop/helpers/transformShopProduct';
import { useExperiments } from '@/modules/experiments/useExperiments';
import { VARIANT_IDENTIFIER } from '@/modules/experiments/config';

export function ShopLikedProductsModule() {
  const { data: sellerData } = useShopByUsername();
  const { isMaxMedium } = useResponsive();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const tCommon = useTranslations('common');
  const tShop = useTranslations('shop');

  const sellerId = sellerData?.data.id;
  const shopName = sellerData?.data.first_name;

  const capitalizedShopName =
    shopName && shopName?.charAt(0).toUpperCase() + shopName?.slice(1);

  const { web_6291_sellers_liked_items_module } = useExperiments([
    'web_6291_sellers_liked_items_module',
  ]);

  const isSellersLikesModuleExperiment =
    web_6291_sellers_liked_items_module === VARIANT_IDENTIFIER;

  const { data } = useLikedProducts({
    sellerId,
    hideSoldProducts: isSellersLikesModuleExperiment,
  });

  const products = getProductsFromPaginatedResponse(data, transformShopProduct);

  const showSeeMore = products && products.length > 9;
  let productsToDisplay = products || [];

  function handleSeeMoreClick() {
    setIsModalOpen(true);
  }

  if (isSellersLikesModuleExperiment && products) {
    if (products.length < 3) {
      return null;
    }
    if (products.length > 9) {
      if (isMaxMedium) {
        productsToDisplay = products.slice(0, 9);
      } else {
        productsToDisplay = products.slice(0, 12);
      }
    }
  }

  if (!isSellersLikesModuleExperiment || !productsToDisplay.length) {
    return null;
  }

  return (
    <div className={styles.container}>
      <span className={styles.seeMoreWrapper}>
        <Text bold>{tShop('SellersLikes', { capitalizedShopName })}</Text>
        {showSeeMore && (
          <ButtonLink
            className={styles.seeMoreText}
            onClick={handleSeeMoreClick}
          >
            {tCommon('SeeMore')}
          </ButtonLink>
        )}
      </span>
      <ol className={styles.productGrid}>
        {productsToDisplay &&
          productsToDisplay.map((product) => (
            <li key={product.id} className={styles.listItem}>
              <ProductListCard product={product} seller={sellerData?.data} />
            </li>
          ))}
      </ol>
      <ShopLikedProductsModuleModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
      />
    </div>
  );
}
