import { useInfiniteQuery } from '@tanstack/react-query';

import { useCurrentLanguage } from '@/modules/language/useCurrentLanguage';
import { RQ_LIKED_PRODUCTS } from '@/modules/ReactQuery/cacheKeys';
import { fetchLikedProducts } from '@/modules/likes/api';
import { LIKES_PAGE_SIZE } from '@/modules/likes/constants';
import { VARIANT_IDENTIFIER } from '@/modules/experiments/config';
import { useExperiments } from '@/modules/experiments/useExperiments';

export function useLikedProducts({
  sellerId,
  hideSoldProducts,
}: {
  sellerId?: number;
  hideSoldProducts?: boolean;
}) {
  const { language: lang } = useCurrentLanguage();
  const { web_upfront_fees } = useExperiments(['web_upfront_fees']);

  return useInfiniteQuery({
    queryKey: [RQ_LIKED_PRODUCTS, { sellerId, hideSoldProducts }],
    queryFn: ({ pageParam }) =>
      fetchLikedProducts(
        {
          params: {
            lang,
            offset_id: pageParam,
            limit: LIKES_PAGE_SIZE,
            force_fee_calculation: web_upfront_fees === VARIANT_IDENTIFIER,
            hide_sold_products: hideSoldProducts,
          },
        },
        sellerId as number
      ),
    initialPageParam: '',
    getNextPageParam: (lastPage) => {
      const { end, last_offset_id } = lastPage.data.meta;
      return !end && last_offset_id ? last_offset_id : null;
    },
    enabled: !!sellerId,
  });
}
