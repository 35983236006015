'use client';

import React from 'react';
import { useTranslations } from 'next-intl';
import { usePathname } from 'next/navigation';
import { Text } from '@depop/web-ui-kit/Typography/Text';
import { Filter3RowIcon } from '@depop/web-ui-kit/Icons/Filter3RowIcon';
import clsx from 'clsx';

import styles from './styles.module.css';

import { ActivityTrackerEventType } from '@/modules/activityTracker/constants';
import { useCurrentUser } from '@/modules/user/useCurrentUser';
import { useActivityTracker } from '@/modules/activityTracker/useActivityTracker';
import { FilterLocation } from '@/modules/filters/types';
import { useSearchFilters } from '@/modules/search/useSearchFilters';
import { getActiveFiltersCount } from '@/modules/search/helpers';
import { SortByModal } from '@/components/Filters/SortByModal';
import { MobileFilterHeader } from '@/components/Filters/MobileFilterHeader';
import {
  CategoryFilter,
  SubcategoryFilter,
} from '@/components/Filters/CategoryFilters';
import { BrandFilter } from '@/components/Filters/BrandFilter';
import { PriceFilter } from '@/components/Filters/PriceFilter';
import { SizeFilter } from '@/components/Filters/SizeFilter';
import { ColourFilter } from '@/components/Filters/ColourFilter';
import { ConditionFilter } from '@/components/Filters/ConditionFilter';
import { OnSaleFilter } from '@/components/Filters/OnSaleFilter';
import { MobileFilterFooter } from '@/components/Filters/MobileFilterFooter';
import { useShopByUsername } from '@/modules/shop/hooks/useShopByUsername';
import { useSearchDrawer } from '@/modules/search/useSearchDrawer';
import { useFiltersTracking } from '@/modules/filters/useFiltersTracking';

export function ShopFilters() {
  const tCommon = useTranslations('common');
  const tSearch = useTranslations('search');
  const pathname = usePathname();
  const { isOpen: isFilterDrawerOpen, setIsOpen: setIsFilterDrawerOpen } =
    useSearchDrawer();
  const { isOpen: isSortDrawerOpen, setIsOpen: setIsSortDrawerOpen } =
    useSearchDrawer();
  const [, user] = useCurrentUser();
  const { sendActivityTrackerEvent } = useActivityTracker({ userId: user?.id });
  const { searchFilters } = useSearchFilters();
  const { data: seller } = useShopByUsername();
  useFiltersTracking();

  const sellerId = seller?.data.id;

  function handleFilterOpenClick(type: string) {
    sendActivityTrackerEvent(ActivityTrackerEventType.FILTER_OPENED_ACTION, {
      filterType: type,
    });
    setIsFilterDrawerOpen((prev) => !prev);
  }

  function handleSortOpenClick() {
    sendActivityTrackerEvent(ActivityTrackerEventType.SORT_OPENED_ACTION, {
      schemaVersion: '1.0',
    });
    setIsSortDrawerOpen((prev) => !prev);
  }

  function closeMobileFilterDrawer() {
    setIsFilterDrawerOpen(false);
  }

  function clearAllFilters() {
    sendActivityTrackerEvent(ActivityTrackerEventType.CLEAR_FILTER_ACTION, {
      schemaVersion: '2.0',
    });
    window.history.pushState(null, '', pathname);
  }

  const activeFiltersCount = getActiveFiltersCount(searchFilters);

  return (
    <div className={styles.container}>
      <div className={styles.mobileControls}>
        <button
          className={clsx(styles.mobileFilterButton, {
            [styles['mobileFilterButton--active']]: activeFiltersCount > 0,
          })}
          onClick={() => handleFilterOpenClick(FilterLocation.Mobile)}
          aria-label={tCommon('Filters')}
        >
          <Filter3RowIcon size={16} />
        </button>
        <button
          className={styles.mobileSortButton}
          onClick={handleSortOpenClick}
        >
          <Text as="span">{tCommon('Sorting.SortBy')}</Text>
        </button>
      </div>

      <div
        className={clsx(
          styles.filtersContainer,
          !isFilterDrawerOpen && styles.hidden
        )}
        data-testid="shopFiltersContainer"
      >
        <MobileFilterHeader
          closeMobileFilterDrawer={closeMobileFilterDrawer}
          title={tSearch('AllFilters')}
        />

        <div className={styles.dropdownsContainer}>
          <CategoryFilter
            closeMobileFilterDrawer={closeMobileFilterDrawer}
            sellerId={sellerId}
          />
          <SubcategoryFilter
            closeMobileFilterDrawer={closeMobileFilterDrawer}
            sellerId={sellerId}
          />
          <BrandFilter
            closeMobileFilterDrawer={closeMobileFilterDrawer}
            sellerId={sellerId}
            persistListOnChange
            showSearchBar={false}
          />
          <PriceFilter
            closeMobileFilterDrawer={closeMobileFilterDrawer}
            sellerId={sellerId}
          />
          <SizeFilter
            closeMobileFilterDrawer={closeMobileFilterDrawer}
            sellerId={sellerId}
          />
          <ColourFilter
            closeMobileFilterDrawer={closeMobileFilterDrawer}
            sellerId={sellerId}
          />
          <ConditionFilter
            closeMobileFilterDrawer={closeMobileFilterDrawer}
            sellerId={sellerId}
            disableUnavailableOptions
          />
        </div>

        <OnSaleFilter />

        <MobileFilterFooter
          handleClear={clearAllFilters}
          handleSubmit={closeMobileFilterDrawer}
          sellerId={sellerId}
        />
      </div>

      <SortByModal
        isOpen={isSortDrawerOpen}
        setOpen={(val) => setIsSortDrawerOpen(val)}
      />
    </div>
  );
}
