'use client';

import React from 'react';
import { useSearchParams } from 'next/navigation';

import { useShopByUsername } from '@/modules/shop/hooks/useShopByUsername';
import { useShopProducts } from '@/modules/shop/hooks/useShopProducts';
import { transformShopProduct } from '@/modules/shop/helpers/transformShopProduct';
import { ShopProductsList } from '@/app/[locale]/[username]/(shop)/components/ProductList';
import { ShopProductsEmptyState } from '@/app/[locale]/[username]/(shop)/components/ShopProductsEmptyState';
import { ListProduct } from '@/modules/product/types';
import { useExperiments } from '@/modules/experiments/useExperiments';
import { useIsViewingOwnShop } from '@/modules/shop/hooks/useIsViewingOwnShop';

export function ShopProducts() {
  const { data: sellerData } = useShopByUsername();
  const sellerId = sellerData?.data.id;
  const queryParams = useSearchParams();

  // aa test for new metric
  useExperiments(['web_productview_derived_metrics_test']);

  const brandIdsQueryParam = queryParams.get('brandIds');
  const targetProductIdQueryParam = queryParams.get('productId');
  const isOwnShop = useIsViewingOwnShop();

  const brandIds =
    brandIdsQueryParam && !isOwnShop ? brandIdsQueryParam : undefined;
  const targetProductId =
    targetProductIdQueryParam && !isOwnShop
      ? Number(targetProductIdQueryParam)
      : undefined;

  const {
    data,
    isError,
    isFetchingNextPage,
    isLoading,
    fetchNextPage,
    hasNextPage,
  } = useShopProducts({
    sellerId,
    brandIds,
    targetProductId,
  });
  const uniqueProductIds = new Set();
  const products = data?.pages.flatMap((page) =>
    page.products.reduce<ListProduct[]>((productList, product) => {
      if (uniqueProductIds.has(product.id)) {
        return productList;
      }

      uniqueProductIds.add(product.id);
      productList.push(transformShopProduct(product));
      return productList;
    }, [])
  );

  return (
    <ShopProductsList
      onEndReached={fetchNextPage}
      hasMore={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
      products={products}
      isError={isError}
      isLoading={isLoading}
      EmptyState={() => <ShopProductsEmptyState />}
    />
  );
}
