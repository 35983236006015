import React from 'react';
import { usePathname } from 'next/navigation';
import { useTranslations } from 'next-intl';
import { Modal, ModalProps } from '@depop/web-ui-kit/Modal';
import { RadioButtons } from '@depop/web-ui-kit/RadioButtons';
import { Button } from '@depop/web-ui-kit/Button';

import styles from './styles.module.css';

import { useSearchFilters } from '@/modules/search/useSearchFilters';
import { SortByValues, TSortByValues } from '@/modules/search/types';
import { getActiveFiltersCount } from '@/modules/search/helpers';

type Props = Pick<ModalProps, 'isOpen' | 'setOpen'>;

export function SortByModal({ isOpen, setOpen }: Props) {
  const {
    searchFilters,
    setSearchFilters: { setSortFilter },
  } = useSearchFilters();
  const tCommon = useTranslations('common');
  const tSearch = useTranslations('search');
  const pathname = usePathname();
  const activeFiltersCount = getActiveFiltersCount(searchFilters);

  function handleSortChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value as TSortByValues;

    if (value === SortByValues.SellersRecommendations) {
      // If no filter has been applied and the user clicks "Relevance", then we should not add the sort query param to the URL.
      // In this scenario, the "Relevance" option will order the products by the seller's manual sort order.
      return window.history.pushState(null, '', pathname);
    }
    return setSortFilter(value);
  }

  const SHOP_SORT_BY_OPTIONS = [
    {
      id:
        activeFiltersCount > 0
          ? SortByValues.Product
          : SortByValues.SellersRecommendations,
      name: 'Sorting.Relevance',
    },
    {
      id: SortByValues.ProductPriceAsc,
      name: 'Sorting.PriceAscending',
    },
    {
      id: SortByValues.ProductPriceDesc,
      name: 'Sorting.PriceDescending',
    },
    {
      id: SortByValues.Recent,
      name: 'Sorting.NewlyListed',
    },
  ];

  return (
    <Modal
      isOpen={isOpen}
      setOpen={setOpen}
      title={tCommon('Sorting.SortBy')}
      content={() => (
        <>
          <div className={styles.radioButtonsWrapper}>
            <RadioButtons
              name="sort"
              activeValue={searchFilters.sort || SHOP_SORT_BY_OPTIONS[0].id}
              onChange={handleSortChange}
              className={styles.radioButtons}
              options={SHOP_SORT_BY_OPTIONS.map(({ id, name }) => ({
                id,
                value: id,
                labelText: tCommon(name),
              }))}
            />
          </div>
          <div className={styles.viewItemsWrapper}>
            <Button block onClick={() => setOpen(false)}>
              {tSearch('ViewItems')}
            </Button>
          </div>
        </>
      )}
    />
  );
}
