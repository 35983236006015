import { useInfiniteQuery } from '@tanstack/react-query';

import { RQ_FILTERED_SHOP_PRODUCTS } from '@/modules/ReactQuery/cacheKeys';
import { fetchShopProductsV3 } from '@/modules/shop/api';
import { useExperiments } from '@/modules/experiments/useExperiments';
import { VARIANT_IDENTIFIER } from '@/modules/experiments/config';
import { useSearchFilters } from '@/modules/search/useSearchFilters';
import { useSearchInput } from '@/modules/search/useSearchInput';
import { buildShopSearchQueryParams } from '@/modules/search/helpers';
import { useSubcategoryNames } from '@/modules/categories/hooks';
import { useCurrentLocation } from '@/modules/location/useCurrentLocation.ts';

export function useFilteredShopProducts(sellerId?: number) {
  const { searchInput } = useSearchInput();
  const { searchFilters } = useSearchFilters();
  const { web_upfront_fees } = useExperiments(['web_upfront_fees']);
  const { location } = useCurrentLocation();
  const subcategoryNames = useSubcategoryNames({
    subcategoryIds: searchFilters.subcategories,
  });
  const searchQueryParams = buildShopSearchQueryParams({
    location,
    searchInput,
    searchFilters,
    subcategoryNames,
  });

  return useInfiniteQuery({
    queryKey: [
      RQ_FILTERED_SHOP_PRODUCTS,
      { sellerId, searchInput, searchQueryParams },
    ],
    queryFn: ({ pageParam }) =>
      fetchShopProductsV3(sellerId as number, {
        params: buildShopSearchQueryParams({
          location,
          searchInput,
          searchFilters,
          subcategoryNames,
          after: pageParam,
          forceFeeCalculation: web_upfront_fees === VARIANT_IDENTIFIER,
        }),
      }),
    initialPageParam: '',
    getNextPageParam: (lastPage) => {
      const { end, last_offset_id } = lastPage.data.meta;
      return !end && last_offset_id ? last_offset_id : null;
    },
    enabled: Boolean(sellerId),
  });
}
