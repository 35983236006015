import {
  ActivityTrackerEventType,
  HomePageModuleNames,
  interactionTypes,
} from '@/modules/activityTracker/constants';
import { useActivityTracker } from '@/modules/activityTracker/useActivityTracker';

export function useTracking() {
  const { sendActivityTrackerEvent } = useActivityTracker();

  function sendModuleClickAction(
    itemClicked: string,
    itemId: number,
    itemPosition: number
  ) {
    sendActivityTrackerEvent(ActivityTrackerEventType.MODULE_CLICK_ACTION, {
      itemClicked,
      itemId,
      itemPosition,
      moduleName: HomePageModuleNames.DepopEdit,
      schemaVersion: '1.0',
    });
  }

  function sendModuleInteractionAction() {
    sendActivityTrackerEvent(
      ActivityTrackerEventType.MODULE_INTERACTION_ACTION,
      {
        interactionType: interactionTypes.MODULE_SEE_MORE_CLICKED,
        moduleName: HomePageModuleNames.DepopEdit,
      }
    );
  }

  return { sendModuleClickAction, sendModuleInteractionAction };
}
