'use client';

import React from 'react';
import { useTranslations } from 'next-intl';
import { useParams } from 'next/navigation';
import { Text } from '@depop/web-ui-kit/Typography/Text';

import styles from './styles.module.css';

import { ShopEmptyState } from '@/app/[locale]/[username]/(shop)/components/EmptyState';
import { useExperiments } from '@/modules/experiments/useExperiments';
import { VARIANT_IDENTIFIER } from '@/modules/experiments/config';
import { useSearchFilters } from '@/modules/search/useSearchFilters';
import { getActiveFiltersCount } from '@/modules/search/helpers';
import { ShopLikedProductsModule } from '@/app/[locale]/[username]/(shop)/components/ShopLikedProductsModule';
import { SellingProductsEmptyState } from '@/app/[locale]/[username]/(shop)/selling/components/EmptyState';
import { useIsViewingOwnShop } from '@/modules/shop/hooks/useIsViewingOwnShop';

export function ShopProductsEmptyStateVariant() {
  const t = useTranslations('shop');
  const { username } = useParams<{ username: string; locale: string }>();
  const { searchFilters } = useSearchFilters();
  const activeFiltersCount = getActiveFiltersCount(searchFilters);
  const isViewingOwnShop = useIsViewingOwnShop();
  const { web_6291_sellers_liked_items_module } = useExperiments([
    'web_6291_sellers_liked_items_module',
  ]);
  const isSellersLikesModuleExperiment =
    web_6291_sellers_liked_items_module === VARIANT_IDENTIFIER;

  const hasFiltersApplied = activeFiltersCount > 0;

  function renderEmptyStateMessage() {
    if (isViewingOwnShop) {
      return <SellingProductsEmptyState />;
    }
    if (hasFiltersApplied) {
      return (
        <ShopEmptyState className={styles.noResultsContainer}>
          <Text bold className={styles.noResultsFound}>
            {t('NoResults1')}
          </Text>
          <Text>{t('NoResults2')}</Text>
        </ShopEmptyState>
      );
    }

    return (
      <ShopEmptyState>
        <Text>{t('NoProducts', { username })}</Text>
      </ShopEmptyState>
    );
  }

  return (
    <>
      {renderEmptyStateMessage()}
      {isSellersLikesModuleExperiment && <ShopLikedProductsModule />}
    </>
  );
}
