'use client';

import React from 'react';
import { Text } from '@depop/web-ui-kit/Typography/Text';
import { useTranslations } from 'next-intl';
import { RearrangeIcon } from '@depop/web-ui-kit/Icons/RearrangeIcon';
import { Button } from '@depop/web-ui-kit/Button';
import { clsx } from 'clsx';

import { ShopProductsEmptyStateVariant } from '../ShopProductsEmptyStateVariant';

import styles from './styles.module.css';

import { useShopByUsername } from '@/modules/shop/hooks/useShopByUsername';
import { useFilteredShopProducts } from '@/modules/shop/hooks/useFilteredShopProducts';
import { getProductsFromPaginatedResponse } from '@/modules/product/helpers/getProductsFromPaginatedResponse';
import { transformShopProduct } from '@/modules/shop/helpers/transformShopProduct';
import { ShopProductsList } from '@/app/[locale]/[username]/(shop)/components/ProductList';
import { useRearrangeProducts } from '@/modules/shop/hooks/useRearrangeProducts';
import { useSoldShopProducts } from '@/modules/shop/hooks/useSoldShopProducts';
import { SoldProductsEmptyState } from '@/app/[locale]/[username]/(shop)/sold/components/EmptyState';
import { useIsViewingOwnShop } from '@/modules/shop/hooks/useIsViewingOwnShop';
import { useShopFilters } from '@/modules/shop/hooks/useShopFilters';
import { ShopFilters } from '@/app/[locale]/[username]/(shop)/components/Filters';

export function ShopProductsVariant() {
  const tShop = useTranslations('shop');
  const { data: sellerData } = useShopByUsername();
  const isViewingOwnShop = useIsViewingOwnShop();
  const sellerId = sellerData?.data.id;

  const { showMobile: showMobileFilters } = useShopFilters(sellerId);

  const {
    data: activeData,
    isError: isActiveError,
    isFetchingNextPage: isFetchingActiveNextPage,
    isLoading: isActiveLoading,
    fetchNextPage: fetchActiveNextPage,
    hasNextPage: hasActiveNextPage,
  } = useFilteredShopProducts(sellerId);

  const {
    data: soldData,
    isError: isSoldError,
    isFetchingNextPage: isFetchingSoldNextPage,
    isLoading: isSoldLoading,
    fetchNextPage: fetchSoldNextPage,
    hasNextPage: hasSoldNextPage,
  } = useSoldShopProducts(sellerId);

  const activeProducts = getProductsFromPaginatedResponse(
    activeData,
    transformShopProduct
  );

  const soldProducts = getProductsFromPaginatedResponse(
    soldData,
    transformShopProduct
  );

  const {
    rearrangeableProductList,
    isRearrangeModeSupported,
    isRearrangeModeActive,
    handleRearrangeInitialiseClick,
    handleSaveRearrangeClick,
    handleCancelRearrangeClick,
    isRearrangedListSaving,
    isRearrangedListSaved,
  } = useRearrangeProducts(sellerId, activeProducts);

  return (
    <div>
      <div>
        <div className={styles.activeHeading}>
          {isViewingOwnShop && !!activeProducts?.length && (
            <span className={styles.activeHeadingText}>
              <Text bold>{tShop('Active')}</Text>
              <Text>
                (
                {tShop('ActiveProductsListing', {
                  products: activeProducts.length,
                })}
                )
              </Text>
            </span>
          )}

          {isRearrangeModeSupported && (
            <div className={styles.rearrangeButtonsContainer}>
              {isRearrangeModeActive ? (
                <div className={styles.rearrangeButtons}>
                  <Button
                    className={clsx(
                      styles.actionButton,
                      styles.rearrangeModeActiveButton
                    )}
                    onClick={() => handleCancelRearrangeClick(true)}
                    disabled={isRearrangedListSaving}
                  >
                    {tShop('Cancel')}
                  </Button>
                  <Button
                    className={clsx(
                      styles.actionButton,
                      styles.rearrangeModeActiveButton
                    )}
                    onClick={handleSaveRearrangeClick}
                    isLoading={isRearrangedListSaving}
                    isSuccess={isRearrangedListSaved}
                    disabled={isRearrangedListSaving || isRearrangedListSaved}
                  >
                    {tShop('Save')}
                  </Button>
                </div>
              ) : (
                <Button
                  icon={<RearrangeIcon />}
                  onClick={handleRearrangeInitialiseClick}
                  disabled={!rearrangeableProductList?.length}
                  className={clsx(
                    styles.actionButton,
                    styles.rearrangeModeActiveButton
                  )}
                >
                  {tShop('Rearrange')}
                </Button>
              )}
            </div>
          )}
        </div>

        {showMobileFilters && <ShopFilters />}

        <ShopProductsList
          className={styles.productListContainer}
          hasMore={hasActiveNextPage}
          isFetchingNextPage={isFetchingActiveNextPage}
          products={activeProducts}
          onEndReached={fetchActiveNextPage}
          isLoading={isActiveLoading}
          isError={isActiveError}
          EmptyState={() => <ShopProductsEmptyStateVariant />}
        />
      </div>

      {!!soldProducts?.length && (
        <div>
          <Text bold className={styles.activeHeadingText}>
            {tShop('SoldItems')}
          </Text>
          <ShopProductsList
            isRearrangeDisabled
            hasMore={hasSoldNextPage}
            isFetchingNextPage={isFetchingSoldNextPage}
            products={soldProducts}
            onEndReached={fetchSoldNextPage}
            isLoading={isSoldLoading}
            isError={isSoldError}
            EmptyState={() => <SoldProductsEmptyState />}
          />
        </div>
      )}
    </div>
  );
}
