import { useInfiniteQuery } from '@tanstack/react-query';

import { useCurrentLanguage } from '@/modules/language/useCurrentLanguage';
import { RQ_SOLD_SHOP_PRODUCTS } from '@/modules/ReactQuery/cacheKeys.ts';
import { fetchSoldShopProducts } from '@/modules/shop/api';
import { SHOP_PAGE_SIZE } from '@/modules/shop/constants';
import { useExperiments } from '@/modules/experiments/useExperiments';
import { VARIANT_IDENTIFIER } from '@/modules/experiments/config';

export function useSoldShopProducts(sellerId?: number, limit = SHOP_PAGE_SIZE) {
  const { language: lang } = useCurrentLanguage();
  const { web_upfront_fees } = useExperiments(['web_upfront_fees']);

  return useInfiniteQuery({
    queryKey: [RQ_SOLD_SHOP_PRODUCTS, sellerId, lang],
    queryFn: ({ pageParam }) =>
      fetchSoldShopProducts(sellerId as number, {
        lang,
        offset_id: pageParam,
        limit,
        force_fee_calculation: web_upfront_fees === VARIANT_IDENTIFIER,
      }),
    initialPageParam: '',
    getNextPageParam: (lastPage) => {
      const { end, last_offset_id } = lastPage.data.meta;
      return !end && last_offset_id ? last_offset_id : null;
    },
    enabled: !!sellerId,
  });
}
